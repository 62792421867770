.rwStartHeader-top {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0;
  margin-bottom: 40px;
}

.rwStartLogos-img {
  margin: 0 30px 0 30px;
}

.rwStartFeature-icon {
  font-size: 150%;
}

.rwStartLogos-section {
  margin-bottom: 40px;
}

.rwStartHomeConcept-section {
  background-color: #f4f4f4;
  margin-bottom: -50px !important;
  padding: 20px !important;
}

.rwDevProcess-img {
  margin: 15px;
}

.rwCloud-1 {
  height: 50px;
  background: url('../img/rw-custom/bg-cloud-1.jpg') no-repeat center center;
}

.rwCloud-2 {
  height: 70px;
  background: url('../img/rw-custom/bg-cloud-2.jpg') no-repeat center center;
}

.rwCloud-3 {
  height: 70px;
  background: url('../img/rw-custom/bg-cloud-3.jpg') no-repeat center center;
}

.rwc-AccountRequestForm-phone {
  width: 12em;
}

.rwc-AccountRequestForm-usersCount {
  width: 6em;
}

.rwc-AccountRequestForm-block {
  margin-bottom: 0 !important;
}
